.form{
 height: auto;
  width: 100%;
  /* background-image: url("../../../public/form_background.png")! important;   */
  background-repeat: no-repeat;
  object-fit: cover;

}
.form>div{
  width: 85%;
  margin:100px auto;
  display: grid;
  grid-template-columns: 30% 30% 30%;
  align-items: center;
  justify-content: space-between;
}
.form > div>div{
  min-height: 250px;
  background-color: red;
  color: white;
  border-radius: 10px;
  padding: 5px;
}
.form > div>div >p{
  text-align: left;
  margin-left: 10px;
}
@media (max-width:790px){
  .form>div{
    width: 85%;
    margin:100 auto;
    display: grid;
    grid-template-columns:  45% 45%;
    align-items: center;
    justify-content: space-between;
    gap: 20px;
  }
}
 
@media (max-width:550px){
  .form>div{
    width: 85%;
    margin:100 auto;
    display: grid;
    grid-template-columns:  98%;
    align-items: center;
    justify-content: space-between;
    gap: 20px;
  }
}