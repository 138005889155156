
 @font-face{
 font-family: 'font-id';
 src: url('https://fontsfree.net//wp-content/fonts/basic/serif/FontsFree-Net-NewYork.ttf');
}
.font_id{
    font-family: 'font-id';
}
.hHfont_id{
    font-family: 'font-id';
    font-size: 200px; 
    border: none;
    }
.hfont_id{
    font-family: 'font-id';
    font-size: 150px; 
    border: none;
}
.hSfont_id{
    font-family: 'font-id';
    font-size: 40px; 
    border: none;
}
.hSsfont_id{
    font-family: 'font-id';
    font-size: 30px; 
    border: none;
}
.pfont_id{
    font-family: 'font-id';
    font-size: 20px; 
    border: none;
}
 
@media (max-width:1500px){
    .hfont_id{
        font-family: 'font-id';
        font-size: 500%; 
        border: none;
        }
}
@media (max-width:900px){
    .hfont_id{
        font-family: 'font-id';
        font-size: 50px; 
        border: none;
        }
}