.footer{
    width: 80%;
    margin:auto;
    padding: 20px;
    display: grid;
    grid-template-columns: 24% 24% 24% 24%;
    gap: 10px;
    justify-content: space-between;
}
.footer>div{
    text-align: left;
    padding: 10px 10%  ;
}.footer_bottom{
    display: grid;
    grid-template-columns: 50% 50%;
    justify-content:space-around;
}

@media(max-width:790px){
    .footer{
        width: 80%;
        margin:auto;
        padding: 20px;
        display: grid;
        grid-template-columns: 32% 32% 32%;
        gap: 10px;
        justify-content: space-around;
    }
}

@media(max-width:550px){
    .footer{
        width: 80%;
        margin:auto;
        padding: 20px;
        display: grid;
        grid-template-columns: 100%;
        gap: 0px;
        justify-content: space-around;
    }
}