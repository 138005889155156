.nav{
    position: fixed; 
    top: 0;
    left:0; 
    width: 100%;
    height: auto;
    box-sizing: border-box;
    transition: top .6s;
    /* overflow: hidden; */
    background:rgba(0,0,0,0.6) ;
    z-index: 1;
} 
.nav--hidden{
    transform: translateY(-85px);
}
#logo{
    font-size: 50px;
    margin-left: 30%;
}
#appin{
    position: relative;
    left: 35%;
}
 
#nav_ul{
    margin-left: 400px;
    gap:'10px'; 
} 
.navbar-toggler{
    position: fixed;
    right: 20px;
    top: 5px;
}
@media(max-width:1700px){
    #nav_ul{
        margin-left: 25%;
        gap:'10px'; 
    }
    #appin{
        position: relative;
        left: 5%;
    }
}
@media(max-width:1000px){
    #nav_ul{
        margin-left: 25px;
        gap:'10px';
        
    }
    #logo{
        font-size: 30px;
        margin-left: 30%;
    }
    #appin{
        position: relative;
        left: 7%;
    }
   
    
}