::placeholder { /* Chrome, Firefox, Opera, Safari 10.1+ */
    color: white;
    opacity: 1; /* Firefox */
    font-family: 'Poppins';
  }
.contact{
    width:80%;
    display: grid;
    grid-template-columns: 49% 49%;
    gap: 10px;
    margin: 100px auto ;

}
.contact> div{
    text-align: left;

}
input{
    border: none;
    max-width: 600px;
    background: transparent;
}
.cline{
    max-width: 600px;
    border: 0.1px solid white;
    margin: 0;
    padding: 0%;
}
@media(max-width:790px){
    .contact{
        width:90%;
        display: grid;
        grid-template-columns: 100%;
        gap: 10px;
        margin: 100px auto ;
    }
}