.serviceHead{
    width: 100%;
    display: grid;
    grid-template-columns: 49% 49%;
    gap: 10px;
    align-items: left;
    justify-content: left;
}
.service_offer{
    width: 100%;
    margin: auto;
    display: grid;
    grid-template-columns: 19% 19% 19% 19% 19%;
    gap:10px;
    align-items: center;
    justify-content: space-around;
}
.service_offer > div{
    /* max-width: 250px; */
    height: 300px;
}
.serviceUp{
    margin-top: -20px;
}
.serviceDun{
    margin-top: 20px;
}
.facial{
    width: 100%;
    display: grid;
    grid-template-columns: 50% 50%;
    align-items: center;
}
.service_price{
     width: 85%;
     margin: 50px auto;
     display: grid;
     grid-template-columns: 24% 24% 24% 24%;
     gap:10px;
}
.service_price > div{
    background-color: black;
    color: white;
    border-radius: 3px;
    padding: 20px;
    text-align: left;
}

@media (max-width:750px){
    .serviceHead{
        width: 100%;
        display: grid;
        grid-template-columns:98%;
        gap: 10px;
        align-items: left;
        justify-content: left;
    }
    .service_offer{
        width: 100%;
        margin: auto;
        display: grid;
        grid-template-columns: 32% 32% 32%  ;
        gap:20px;
        align-items: center;
        justify-content: space-around;
    }
    .serviceUp{
        margin-top: 0px;
    }
    .serviceDun{
        margin-top: 0px;
    }
    .facial{
        width: 90%;
        margin: auto;
        display: grid;
        grid-template-columns: 100%;
        align-items: center;
    }
    .service_price{
        width: 85%;
        margin: 50px auto;
        display: grid;
        grid-template-columns:49% 49%;
        gap:10px;
   }
}


@media (max-width:550px){
    .service_offer{
        width: 100%;
        margin: auto;
        display: grid;
        grid-template-columns:100%  ;
        gap:20px;
        align-items: center;
        justify-content: space-around;
    }
    .service_price{
        width: 90%;
        margin: 50px auto;
        display: grid;
        grid-template-columns:98%;
        gap:20px;
   }
}