.hair_hade{
    display: grid;
    grid-template-columns: 50% 50%;
    gap: 10px;
    justify-content: space-around;
    align-items: center;
}
.bridal_tret1{
    display: grid;
    grid-template-columns: 20% 20% 20% 20% 20%;
    align-items: center;
    justify-content: space-around;
    gap:20px;
}
 
video{
    width:180px !important;
    height: 230px;
}
.up{
    margin-top: -25px;
}
.dn{
    margin-top: 25px;
}
.div_h1{
    display:block;
}
@media(max-width:890px){
    .bridal_tret1{
        display: grid;
        grid-template-columns: 32% 32% 32%;
        align-items: center;
        justify-content: space-around;
        gap: 20px;
    }
    .bridal_tret1 > div{
        margin:auto;
    }
    .up{
        margin-top: 0px;
    }
    .dn{
        margin-top: 0px;
    }
    .hair_hade{
        display: grid;
        grid-template-columns:98%;
        gap: 10px;
        justify-content: space-around;
        align-items: center;
    }
    .div_h1{
        display:none;
    }
}

@media(max-width:690px){
    .bridal_tret1{
        display: grid;
        grid-template-columns: 49%  49%;
        align-items: center;
        justify-content: space-around;
    }
}

@media(max-width:450px){
    .bridal_tret1{
        display: grid;
        grid-template-columns: 98%;
        align-items: center;
        justify-content: space-around;
    } 
}