.about_head{  
    width: 100%;
    display: grid;
    grid-template-columns:44% 54%;
    gap: 30px;
}
.aboutDiv1{
    text-align:'left';
    margin:50px ; 
}
.ahSfont_id{
    font-family: 'font-id';
    font-size: 40px; 
    border: none;
    }

    .about_pro{
        display: grid;
        align-items: center;
        padding:10px;
        color: white; 
        min-height: 100vh;
    }
  
@media(max-width:1050px){
    .about_head{ 
        margin-top: 65px;  
        display: grid;
        grid-template-columns:98%;
    }
    .aboutDiv1{
        text-align:'left';
        margin:20px; 
    }
}
@media (max-width:550px){
    .ahSfont_id{
        font-family: 'font-id';
        font-size: 25px; 
        border: none;
        }
}