 p{
  font-size: 20px;
  font-family: 'Poppins';
 }
 .home{
  margin-top: -70px;
  padding: 0;
 }
 .department_div{
    width: 90%;
    margin:20px auto;
    display: grid;
    grid-template-columns:65% 35% ;
    gap: 10px; 
 }
 .department_div>div>div{
    margin: auto; 
 }
  .hide_div{
    display: block;
  }
 .our_service{
   /* margin-top :20px; */
   background-color: rgb(37, 36, 36);
   color: white; 
   min-height: 100vh;
 }
 .service_head{
   width: 60%;
   color: white; 
   display: grid;
   grid-template-columns: 25% 50% 25%; 
   align-items: center;
   margin: 20px auto  ; 
 }
 .service_head > div{
   height: 0.5px; 
    background-color: white;
 }
 .service{
   display: grid;
   grid-template-columns: 49% 2% 49%;
 }
 .service_line{
   height: 85vh;
   width: 0.5px;
   background-color: white;
 }
 
  .professional{ 
   display: grid;
   align-items: center;
   padding:10px;
   color: white; 
   min-height: 100vh;
  }
  .profe_butn{
    margin: 0 40%;
  }
  .bridal{
   width: 80%;
   margin:100px auto;
   display: grid;
   grid-template-columns: 64% 34%;
  }
  .bridal2{
    width: 80%;
    margin:100px auto;
    display: grid;
    grid-template-columns: 34% 20% 44% ; 
   }

 @media (max-width:720px){
  p{
    font-size: 17px;
  }
    .department_div{
        display: grid;
        grid-template-columns: 98%;
        gap: 50px; 
     }
     .hide_div{
      display: none;
    } 
     .service{
      display: grid;
      grid-template-columns:98%  ;
      gap: 100px;
    }
    .service_line{
      display: none;
    }
    .profe_butn{
      margin: 0 10%;
    }
    .bridal{
      width: 90%;
      margin:50px auto;
      display: grid;
      grid-template-columns: 98%;
      gap: 20px;
     }
     .bridal2{
      width: 90%;
      margin:50px auto;
      display: grid;
      grid-template-columns: 98%;
      gap: 20px;
     }
 }
 