.bridal_tret2{
    display: grid;
    grid-template-columns: 20% 20% 20% 20% 20%;
    align-items: center;
    justify-content: space-around;
    gap:20px;
} 
.up{
    margin-top: -25px;
}
.dn{
    margin-top: 25px;
}
@media(max-width:890px){
    .bridal_tret2{
        display: grid;
        grid-template-columns: 32% 32% 32%;
        align-items: center;
        justify-content: space-around;
        gap: 20px;
    }
    .bridal_tret2 > div{
        margin:auto;
    }
    .up{
        margin-top: 0px;
    }
    .dn{
        margin-top: 0px;
    }
}

@media(max-width:690px){
    .bridal_tret2{
        display: grid;
        grid-template-columns: 49%  49%;
        align-items: center;
        justify-content: space-around;
    }
}

@media(max-width:450px){
    .bridal_tret2{
        display: grid;
        grid-template-columns: 98%;
        align-items: center;
        justify-content: space-around;
    }
}