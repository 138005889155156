 ::selection {
            /* background: #edf3f3; */
        }
        .shape1 { 
            stroke-dasharray: 1100;
            stroke-dashoffset: 1100;
            stroke-width: .5px; 
            fill: transparent;
            /* stroke: #e5fefd; */
            border-bottom: 0 solid black;
            transition-timing-function: linear;
            transition: stroke-dashoffset 2s, stroke-dasharray 2s;
        } 
        .text { 
            font-size: 16px;
            line-height: 40px;
            color: white; 
            margin-top :-42px;
            text-align: left;
            height: 40px;
            padding: 3px 21px;
        }

        .svg-wrapper:hover .shape1{
            stroke-dashoffset: 0;
            stroke-dasharray: 1120;
        }
@media(max-width:990px){
    .text { 
        font-size: 16px;
        line-height: 0px;
        color: white; 
        margin-top :-40px;
        text-align: left;
        height: 40px;
        padding: 20px 20px;
    }
}